import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import LoadingScreen from '../components/LoadingScreen';

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <LandingPage />
        },
        {
          path: '/contact',
          element: <ContactPage />
        },
        {
          path: '/legal/terms',
          element: <TermsPage />
        },
        {
          path: '/legal/privacy-policy',
          element: <PrivacyPolicyPage />
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Dashboard
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const TermsPage = Loadable(lazy(() => import('../pages/TermsPage')));
const PrivacyPolicyPage = Loadable(lazy(() => import('../pages/PrivacyPolicyPage')));
const ContactPage = Loadable(lazy(() => import('../pages/ContactPage')));
