// material
import { useTheme } from '@material-ui/core/styles';
import { Box, BoxProps } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  return (
    <Box sx={{ width: 60, height: 60, pt: 1,...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300 300">
            <title>Page 1</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Final-File-01" transform="translate(-441.000000, -872.000000)" fill={PRIMARY_DARK}>
                    <path d="M726.098,901.024 L601.012,1117.68 C593.813,1130.15 575.817,1130.15 568.619,1117.68 L560.179,1103.06 L693.019,872.971 L709.901,872.971 C724.298,872.971 733.296,888.556 726.098,901.024" id="Fill-1"></path>
                    <polyline id="Fill-2" points="503.963 872.971 545.739 872.97 590.708 950.86 635.677 872.971 677.454 872.97 590.708 1023.22 503.963 872.971"></polyline>
                    <path d="M523.725,1039.92 L443.534,901.025 C436.335,888.556 445.333,872.97 459.731,872.97 L488.396,872.97 L554.254,987.039 L523.725,1039.92" id="Fill-3"></path>
                    <polyline id="Fill-4" points="582.925 1036.7 552.396 1089.58 531.507 1053.4 562.037 1000.52 582.925 1036.7"></polyline>
                    <polyline id="Fill-5" points="561.305 872.971 620.111 872.971 590.708 923.899 561.305 872.971"></polyline>
                </g>
            </g>
        </svg>
    </Box>
  );
}
